const graph = (
  state = {
    selected: "2",
    zoom: 1,
  },
  action,
) => {
  switch (action.type) {
    case "SET_GRAPH_ZOOM":
      return {
        ...state,
        zoom: action.zoom,
      };
    case "ON_SELECT_NODE":
      return {
        ...state,
        selected: action.node.id,
      };
    case "SELECT_NODE_BY_ID":
      return {
        ...state,
        selected: action.id,
      };
    default:
      return state;
  }
};

export default graph;

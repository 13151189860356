import * as d3 from "d3";
import isequal from "lodash/isEqual";

import signals from "../utils/signals";

import "./TidyTree.scss";
import { prepareData } from "../utils/data";

class TidyTree {
    constructor(el) {
        this.svg = d3.select(el).append("svg").style("width", "100%").style("height", "100%");

        this.counter = this.svg.append("text").attr("dx", 20).attr("dy", 20);

        signals.on("center_graph", this.center.bind(this));
    }

    create(data, state, config = {}) {
        this.data = data;
        this.state = state;

        // Defaults
        if (!config.onClickNode) config.onClickNode = () => {};
        if (!config.onRenderComplete) config.onRenderComplete = () => {};
        this.config = config;

        const root = tree(data);

        let x0 = Infinity;
        let x1 = -x0;
        root.each((d) => {
            if (d.x > x1) x1 = d.x;
            if (d.x < x0) x0 = d.x;
        });

        this.positition = {
            x: root.dy / 3,
            y: root.dx - x0,
        };

        const g = this.svg.append("g").attr("class", "container").attr("font-family", "sans-serif").attr("font-size", 10).attr("transform", `translate(${this.positition.x},${this.positition.y}) scale(${this.state.zoom},${this.state.zoom})`);

        this.links = g.append("g").attr("fill", "none").attr("stroke", "#555").attr("stroke-opacity", 0.4).attr("stroke-width", 1.5).attr("class", "links");

        this.nodes = g.append("g").attr("class", "nodes").attr("stroke-linejoin", "round").attr("stroke-width", 3);

        this.updateSVG(root, state, config);
        this.setupDragHandlers(state, config);
        this.center();
        this.config.onRenderComplete();
    }

    update(data, state, config) {
        if (!isequal(data, this.data)) {
            const root = tree(data);
            this.updateSVG(root, state, config);
            this.updateRootNodeName(root);
            this.data = data;
        }

        // Only state has changed, while underlying data is the same.

        if (!isequal(state, this.state)) {
            this.state = state;
            const { selected } = state;

            this.svg.selectAll(".nodes circle").attr("fill", (d) => {
                return d.data.id === selected ? "red" : d.children ? "#555" : "#999";
            });

            this.svg.select(".container").attr("transform", `translate(${this.positition.x},${this.positition.y}) scale(${this.state.zoom},${this.state.zoom})`);
        }

        this.config.onRenderComplete();
    }

    updateSVG(root, state, config) {
        this.links
            .selectAll("path")
            .data(root.links(), (d) => `${d.id}_${d.name}`)
            .join("path")
            .attr(
                "d",
                d3
                    .linkHorizontal()
                    .x((d) => d.y)
                    .y((d) => d.x)
            );

        const node = this.nodes.selectAll(".node").data(root.descendants(), (d) => `${d.id}_${d.name}`);

        node.exit().remove();

        // ENTER
        const newNode = node.enter().append("g").attr("class", "node").on("click", handleSelectNode(config.onSelectNode));

        newNode.append("circle").attr("r", 2.5);

        newNode.append("text").attr("dy", "0.31em").clone(true).lower().attr("stroke", "white");

        // UPDATE
        this.nodes.selectAll(".node").attr("transform", (d) => `translate(${d.y},${d.x})`);

        this.nodes
            .selectAll("text")
            .attr("x", (d) => (d.children ? -6 : 6))
            .attr("text-anchor", (d) => (d.children ? "end" : "start"))
            .attr("class", (d) => `node-name-${d.data.id}`)
            .text((d) => {
                return d.data.name;
            });

        this.nodes.selectAll("circle").attr("fill", (d) => {
            return d.data.id === state.selected ? "red" : d.children ? "#555" : "#999";
        });
    }

    updateRootNodeName(root) {
        const d = root.descendants()[0];
        this.svg.selectAll(`text.node-name-${d.data.id}`).text(d.data.name);
    }

    setupDragHandlers(state, config) {
        const drag = d3.drag().on("drag", this.onDragging.bind(this));

        this.svg.call(drag);
    }

    onDragging(e) {
        const newPos = {
            x: this.positition.x + d3.event.dx,
            y: this.positition.y + d3.event.dy,
        };
        this.updateContainerPosition(newPos);
    }

    updateContainerPosition({ x, y }) {
        this.positition.x = x;
        this.positition.y = y;

        this.svg.select(".container").attr("transform", `translate(${this.positition.x},${this.positition.y}) scale(${this.state.zoom},${this.state.zoom})`);
    }

    destroy(el) {}

    center() {
        const svgSize = this.svg.node().getBoundingClientRect();
        const containerSize = this.svg.select(".container").node().getBBox();

        this.updateContainerPosition({
            x: svgSize.width / 2 - containerSize.width / 2,
            y: svgSize.height / 2 - containerSize.height / 2 + 40, // 40 from menu height
        });
    }
}

const handleSelectNode = (callback) => {
    return (d, i) => {
        callback(d.data);
    };
};

const tree = (data) => {
    const root = d3.hierarchy(prepareData(data));
    root.dx = 50;
    root.dy = 150;
    return d3.tree().nodeSize([root.dx, 100])(root);
};

export default TidyTree;

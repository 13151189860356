export const setCurrentWorkspace = id => ({
  type: "SET_CURRENT_WORKSPACE",
  id,
});

export const setCurrentDocument = id => ({
  type: "SET_CURRENT_DOCUMENT",
  id,
});

export const setWorkspaceName = (id, name) => ({
  type: "SET_WORKSPACE_NAME",
  id,
  name,
});

export const setDocumentName = (id, name) => ({
  type: "SET_DOCUMENT_NAME",
  id,
  name,
});

const windowmanager = (
  state = {
    current_overlay: "intro",
  },
  action,
) => {
  // Main reducer that takes care of actions that modify window state
  switch (action.type) {
    case "SET_ACTIVE_WINDOW":
      return {
        ...state,
        active: action.name,
      };

    case "ON_SELECT_NODE":
    case "NEW_NODE":
      return {
        ...state,
        active: "graph",
      };

    case "SET_CURRENT_OVERLAY":
      return {
        ...state,
        current_overlay: action.key,
      };

    case "CLOSE_OVERLAY":
      return {
        ...state,
        current_overlay: null,
      };

    default:
      return state;
  }
};

export default windowmanager;

import { createStore } from "redux";
import rootReducer from "./reducers";
import throttle from "lodash/throttle";

import { loadState, saveState } from "./utils/localstorage";

const store = createStore(
  rootReducer,
  loadState(),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

store.subscribe(
  // TODO: show saving state.
  // last saved
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

export default store;

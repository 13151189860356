import React from "react";
import { connect } from "react-redux";

import TidyTreeD3 from "../../d3-components/TidyTree";

class TidyTree extends React.Component {
  constructor(props) {
    super(props);

    this.tidyTreeNode = React.createRef();

    this.chartOptions = {
      onSelectNode: this.props.onSelectNode,
    };
  }

  componentDidMount() {
    this.chart = new TidyTreeD3(this.tidyTreeNode.current);
    this.chart.create(this.props.data, this.props.graph, this.chartOptions);
  }

  componentDidUpdate() {
    this.chart.update(this.props.data, this.props.graph, this.chartOptions);
  }

  render() {
    return (
      <div
        className="tidy-tree"
        style={{
          width: "100%",
          height: "100%",
        }}
        ref={this.tidyTreeNode}
      ></div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TidyTree);

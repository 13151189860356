const defaultstate = {
  current_workspace: "1234",
  current_document: "abcd",
  spaces: {
    "1234": {
      id: "1234",
      name: "Dune",
      public: false,
      owner_id: 1,
      collaborators: [2, 3],
    },
  },
  documents: {
    abcd: {
      id: "abcd",
      name: "People",
      public: false,
      owner_id: 1,
      collaborators: [4, 5],
      space_id: "1234",
    },
  },
};

const workspace = (state = defaultstate, action) => {
  switch (action.type) {
    case "SET_CURRENT_WORKSPACE":
      return {
        ...state,
        current_workspace: action.id,
      };

    case "SET_CURRENT_DOCUMENT":
      return {
        ...state,
        current_document: action.id,
        current_workspace: state.documents[action.id].space_id,
      };

    case "SET_WORKSPACE_NAME":
      return {
        ...state,
        spaces: {
          ...state.spaces,
          [action.id]: {
            ...state.spaces[action.id],
            name: action.name,
          },
        },
      };

    case "SET_DOCUMENT_NAME":
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.id]: {
            ...state.documents[action.id],
            name: action.name,
          },
        },
      };
    default:
      return state;
  }
};

export default workspace;

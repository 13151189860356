import React from "react";
import { connect } from "react-redux";

import { onSelectNode, onGraphRerender } from "../../actions/graph";

import TidyTree from "./TidyTree";

function Graph({ data, graph, setGraphZoom, onSelectNode, onGraphRerender }) {
  return (
    <div className="graph">
      <TidyTree
        graph={graph}
        count={1}
        data={data}
        onSelectNode={onSelectNode}
        onGraphRerender={onGraphRerender}
      />
    </div>
  );
}

const mapStateToProps = ({ data, graph }) => ({
  data,
  graph,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectNode: (node) => dispatch(onSelectNode(node)),
  onGraphRerender: () => dispatch(onGraphRerender()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Graph);

import React, { useState } from 'react';


const ResponseOverlay = () => {
  const [dismissed, setDismissed] = useState(false);

  return !dismissed && <div className="mobile-overlay">
    <div className="text">
      Sorry this website has not been optimised for small screens yet. :( The recommended size is bigger than 1024 x 700. If it looks squished just zoom out on your browser (Cmd + Minus). <br />
      <br />

      <button onClick={() => setDismissed(true)}>Fuggedaboudit.</button>

    </div>
  </div>
}

export default ResponseOverlay
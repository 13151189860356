import { v4 as uuidv4 } from "uuid";

export const newNode = (parent_id, name = "") => ({
  type: "NEW_NODE",
  parent_id,
  name,
  id: uuidv4(),
});

export const editNode = (id, data) => ({
  type: "EDIT_NODE",
  id,
  data,
});

export const editNodeBlock = (node_id, block_id, data) => ({
  type: "EDIT_NODE_BLOCK",
  node_id,
  block_id,
  data,
});

export const deleteNode = id => ({
  type: "DELETE_NODE",
  id,
});

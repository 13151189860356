import cloneDeep from "lodash/cloneDeep";

export const prepareData = data => {
  let nodes = cloneDeep(data.nodes);

  // add children_ids
  Object.keys(nodes).forEach(k => {
    const d = nodes[k];
    if (d.parent_id && nodes[d.parent_id]) {
      if (!nodes[d.parent_id].children) {
        nodes[d.parent_id].children = [];
      }
      nodes[d.parent_id].children.push(d.id);
    }
  });

  const convertIdsToObject = elem => {
    if (!elem.children) return elem;
    elem.children = elem.children.map(id => convertIdsToObject(nodes[id]));
    return elem;
  };

  const keys = Object.keys(nodes);

  for (let i = 0; i < keys.length; i++) {
    const d = nodes[keys[i]];
    if (!d.parent_id) {
      convertIdsToObject(d);
      // TODO: return multiple = multiple chards on the graph
      return d; // we can return here as there should be only one without a parent_id
    }
  }
};

export const prepareDataForDropdown = (data, selected) => {
  const graphData = prepareData(data);
  const SPACER = "-";

  const getItems = (item, depth) => {
    if (item.id === selected) return [];

    const items = [
      {
        id: item.id,
        name: SPACER.repeat(depth) + (depth ? " " : "") + item.name,
      },
    ];

    if (item.children) {
      return [
        ...items,
        ...[].concat(...item.children.map(d => getItems(d, depth + 1))),
      ];
    }

    return items;
  };

  return getItems(graphData, 0);
};

export const downloadState = (state, filename) => {
  const json = encodeURIComponent(JSON.stringify(state));
  const element = document.createElement("a");
  element.setAttribute("href", "data:application/json;charset=utf-8," + json);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

import React, { useState } from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import OverlayWrapper from "./OverlayWrapper";
import { closeOverlay } from "../../actions/window";

const IntroOverlay = ({ closeOverlay }) => {
    const PAGE_COUNT = 3;
    const [page, setPage] = useState(0);

    return (
        <OverlayWrapper>
            <div className="intro-overlay ui-body-copy">
                <div className="pages">
                    <div
                        className="page-slider"
                        style={{
                            width: PAGE_COUNT * 100 + "%",
                            left: page * -100 + "%",
                        }}
                    >
                        <div className="page">
                            <h2>
                                <img className="logo" src="/assets/img/noun_nodes_2397553.svg" alt="Systems Icon" /> Welcome to Systems!
                            </h2>
                            <p className="one-liner">Systems is a node graph and text editor for systems thinkers.</p>
                            <p>The idea behind Systems is that when mapping out a group of connected entities (concepts, organisations, people, etc...) it is sometimes useful to chart these out visually while taking long-form notes about the individual nodes or connections.</p>
                            <p>
                                I've often found that when listening to a podcast, reading a book or even just trying to organise my own thoughts I was craving a tool that could help me effectively map out the terrain while letting me go into depth about some of the topics I was thinking about. This
                                is the first scratch at that itch.
                            </p>
                            <p>
                                The interface is divided into two parts: the graph and the text editor. In the graph you can quickly map out all the players in the scene similar to when you are brainstorming or mindmapping. The text editor lets you write notes in as much or as little detail as you
                                would like. Finally there is (ahem. will be) the "document view". This view allows you to bring all of the content into a single document that you can read yourself or share with others.
                            </p>
                        </div>
                        <div className="page page-2">
                            <div className="page-2-container">
                                <div className="section">
                                    <img src="/assets/img/graph_intro_icon.png" alt="Graph page intro icon" />
                                    <h3>The Graph</h3>
                                    <p>The graph is a visual representation of your data as well as an editor. Select a node (the selected node turns red), and then create more nodes using "Enter" (child node) or "Cmd + Enter" (sibling node).</p>
                                </div>
                                <div className="section">
                                    <img src="/assets/img/editor_intro_icon.png" alt="Editor page intro icon" />
                                    <h3>The Editor</h3>
                                    <p>The editor section is pretty simple. It contains the node title, a dropdown which can be used to change the parent node, and a text editor with some basic formatting options.</p>
                                </div>
                            </div>
                        </div>
                        <div className="page">
                            <img src="/assets/img/systemslogo.svg" alt="Systems Logo" />
                            <h2>Where's the data?</h2>
                            <p>All of your data is stored inside your browser. There is no need to save or keep this tab open, next time you visit this page it will be exactly like you left it. There is an option to download your data as a backup or in case you want to add it to another browser.</p>
                            <h2>
                                Feedback! Email me at <a href="mailto:systems@lsei.co">systems@lsei.co</a>.
                            </h2>
                            <p>I'd love to hear all of your thoughts on this and your hear more about your general thinking / percolating / information amalgamation process.</p>
                            <p>It's a super crude early prototype but does the general idea jive with you? Does the interface make sense? Where is my moleskin? Let me know!</p>
                        </div>
                    </div>
                </div>
                <div className="pagination">
                    {[...Array(PAGE_COUNT).keys()].map((i) => (
                        <span key={i} onClick={() => setPage(i)} className={classnames("page", { current: i === page })}>
                            {" "}
                        </span>
                    ))}
                </div>

                <div className="intro-nav left">
                    {page > 0 && (
                        <div className="button" onClick={() => setPage(page - 1)}>
                            {"<"}- Back
                        </div>
                    )}
                </div>
                <div className="intro-nav right">
                    {page < PAGE_COUNT - 1 && (
                        <div className="button" onClick={() => setPage(page + 1)}>
                            Next ->
                        </div>
                    )}

                    {page === PAGE_COUNT - 1 && (
                        <div className="button" onClick={() => closeOverlay()}>
                            Let me try it out!
                        </div>
                    )}
                </div>
            </div>
        </OverlayWrapper>
    );
};

const mapDispatchToProps = {
    closeOverlay,
};

export default connect(null, mapDispatchToProps)(IntroOverlay);

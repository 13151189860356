import React from "react";
import { connect } from "react-redux";

import signals from "../../utils/signals";
import { setGraphZoom, selectNodeById } from "../../actions/graph";
import { setCurrentOverlay } from "../../actions/window";
import { newNode, deleteNode } from "../../actions/data";
import { NEW_NODE_NAME } from "../../utils/contstants";
import { downloadState } from "../../utils/data";
import { resetData } from "../../utils/localstorage";

const GRAPH_ZOOM_MIN = 0.5;
const GRAPH_ZOOM_MAX = 3;

const GraphMenu = ({ state, setGraphZoom, setCurrentOverlay, newNode, selectNodeById, deleteNode }) => {
    const { graph, data } = state;

    const zoomOut = () => setGraphZoom(Math.max(graph.zoom - 0.5, GRAPH_ZOOM_MIN));
    const zoomIn = () => setGraphZoom(Math.min(graph.zoom + 0.5, GRAPH_ZOOM_MAX));
    const resetZoom = () => setGraphZoom(1);
    const centerGraph = () => {
        signals.dispatch("center_graph");
    };

    // const { workspace } = state;
    // const space = workspace.spaces[workspace.current_workspace];
    // const document = workspace.documents[workspace.current_document];

    const currentNode = data.nodes[graph.selected] || {};

    return (
        <div className="graph-menu">
            <div className="node-tools">
                <button
                    disabled={!currentNode.id}
                    onClick={() => {
                        const node = newNode(currentNode.id, NEW_NODE_NAME);
                        selectNodeById(node.id);
                    }}
                >
                    + Child Node
                </button>
                <button
                    disabled={!currentNode.parent_id}
                    onClick={() => {
                        if (!currentNode.parent_id) return;
                        const node = newNode(currentNode.parent_id, NEW_NODE_NAME);
                        selectNodeById(node.id);
                    }}
                >
                    + Sibling Node
                </button>
                <button
                    disabled={!currentNode.parent_id}
                    onClick={() => {
                        if (!currentNode.parent_id) return;
                        selectNodeById(currentNode.parent_id);
                        deleteNode(currentNode.id);
                    }}
                >
                    Delete Selected Node
                </button>
            </div>

            <div className="zoom-menu">
                <button onClick={centerGraph} title="Center the graph in the current view.">
                    Center
                </button>
                <button onClick={resetZoom} title="Bring zoom back to 100%">
                    Reset Zoom
                </button>
                <button onClick={zoomOut} title="Decrease zoom level. Zooms out.">
                    Zoom -
                </button>
                <button onClick={zoomIn} title="Increase zoom level. Zooms in.">
                    Zoom +
                </button>
            </div>

            <ul className="main-menu">
                <li>
                    Menu
                    <ul>
                        <li onClick={() => setCurrentOverlay("intro")}>Show Intro</li>
                        <li onClick={() => downloadState(state, "systems.json")}>Export Current Document (Download JSON File)</li>
                        <li
                            onClick={() => {
                                const ok = window.confirm("Are you sure you want to clear all the data?");
                                if (ok) {
                                    resetData();
                                }
                            }}
                        >
                            Clear all data and reset.
                        </li>
                    </ul>
                </li>
                <li onClick={() => setCurrentOverlay("document")}>Document View</li>
                {/* <li
          title="Open workspaces menu"
          onClick={() => {
            setCurrentOverlay("workspaces");
          }}
        >
          {space.name}
        </li>
        {document && <li>> {document.name}</li>} */}
            </ul>
        </div>
    );
};

const mapStateToProps = (state) => ({ state });

const mapDispatchToProps = {
    newNode,
    setGraphZoom,
    setCurrentOverlay,
    selectNodeById,
    deleteNode,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphMenu);

import React from "react";
import { connect } from "react-redux";

import "./DocumentOverlay.scss";
import { prepareData } from "../../../utils/data";
import DocumentNode from "./DocumentNode";
import { setCurrentOverlay } from "../../../actions/window";

const gatherNodes = (node) => {
    return [<DocumentNode {...node} key={node.id} />, ...(node.children ? [].concat(...node.children.map(gatherNodes)) : [])];
};

const DocumentOverlay = ({ data, setCurrentOverlay }) => {
    const tree = prepareData(data);
    const nodes = gatherNodes(tree);

    return (
        <div className="document-overlay">
            <div className="document-wrapper">{nodes}</div>
            <div
                className="ix-close-overlay"
                onClick={() => {
                    setCurrentOverlay(null);
                }}
            >
                Close
            </div>
        </div>
    );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
    setCurrentOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentOverlay);

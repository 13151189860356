import React from "react";
import { connect } from "react-redux";

import WorkspacesOverlay from "./WorkspacesOverlay";
import IntroOverlay from "./IntroOverlay";
import DocumentOverlay from "./DocumentOverlay/DocumentOverlay";

const Overlays = ({ window }) => {
  switch (window.current_overlay) {
    case "intro":
      return <IntroOverlay />;

    case "workspaces":
      return <WorkspacesOverlay />;

    case "document":
      return <DocumentOverlay />;

    default:
      return null;
  }
};

const mapStateToProps = ({ window }) => ({
  window,
});

export default connect(mapStateToProps)(Overlays);

import React from "react";
import DocumentNodeBlock from "./DocumentNodeBlock";

const DocumentNode = ({ id, name, blocks, children }) => {
    return (
        <div className="document-node" id={id}>
            <h1 className="node-title">{name}</h1>
            <div className="blocks">
                {blocks.map((b) => (
                    <DocumentNodeBlock nodeId={id} {...b} key={b.id} />
                ))}
            </div>
            {children && (
                <div className="node-links">
                    Linked Nodes:
                    <ol>
                        {children.map((d) => (
                            <li key={d.id}>
                                <a href={`#${d.id}`}>{d.name}</a>
                            </li>
                        ))}
                    </ol>
                </div>
            )}
            <hr />
        </div>
    );
};

export default DocumentNode;

export const setActiveWindow = name => ({
  type: "SET_ACTIVE_WINDOW",
  name,
});

export const setCurrentOverlay = key => ({
  type: "SET_CURRENT_OVERLAY",
  key,
});

export const closeOverlay = () => ({
  type: "CLOSE_OVERLAY",
});

import React from "react";
import Markdown from "../../editor/Blocks/Markdown";

const DocumentNodeBlock = (props) => {
    const { type } = props;
    let content = null;

    switch (type) {
        case "text":
            content = <Markdown {...props} onChange={() => {}} placeholder={" "} />;
            break;

        default:
            break;
    }
    return <div className={`document-node-block dnb-${type}`}>{content}</div>;
};

export default DocumentNodeBlock;

// Super basic signaling/channels in javascript.
// setup listeners for arbitrary events and then dispatch the events with optional data attached to it.
const listeners = {};

export const on = (event, callback) => {
  if (!listeners[event]) {
    listeners[event] = [];
  }

  listeners[event].push(callback);
};

export const dispatch = (event, args = []) => {
  console.log(`[signal] ${event}`);
  if (!listeners[event]) return;

  if (!Array.isArray(args)) {
    console.error(
      "args in event dispatch must be an array. was: " + typeof args,
    );
    console.log(args);
    return;
  }

  listeners[event].forEach((callback) => {
    callback(...args);
  });
};

export default {
  on,
  dispatch,
};

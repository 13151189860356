import React from "react";

import Graph from "../components/graph/Graph";
import Editor from "../components/editor/Editor";
import WindowManager from "../components/WindowManager";
import GraphMenu from "../components/graph/GraphMenu";
import Overlays from "../components/overlays/Overlays";
import ResponseOverlay from "../components/ResponsiveOverlay";

function Index() {
  return (
    <div className="index">
      <div className="two-cols">
        <div className="graph-container">
          <GraphMenu />
          <Graph />
        </div>
        <div className="editor-container">
          <Editor />
        </div>
      </div>
      <WindowManager />
      <Overlays />
      <ResponseOverlay />
    </div>
  );
}

export default Index;

export const onSelectNode = node => ({
  type: "ON_SELECT_NODE",
  node,
});

export const selectNodeById = id => ({
  type: "SELECT_NODE_BY_ID",
  id,
});

export const setGraphZoom = zoom => ({
  type: "SET_GRAPH_ZOOM",
  zoom,
});

export const onGraphRerender = () => ({
  type: "ON_GRAPH_RERENDER",
});

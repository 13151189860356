import React from "react";
import { connect } from "react-redux";

import { editNodeBlock } from "../../actions/data";

import Markdown from "./Blocks/Markdown";

const ContentBlock = ({ type, editNodeBlock, ...props }) => {
  let Block;
  switch (type) {
    case "text":
      Block = Markdown;
      break;

    default:
      return null;
  }

  return (
    <div className="block">
      <Block {...props} onChange={editNodeBlock} />
    </div>
  );
};

const mapDispatchToProps = {
  editNodeBlock,
};

export default connect(null, mapDispatchToProps)(ContentBlock);

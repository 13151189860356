import { v4 as uuidv4 } from "uuid";

import defaultdata from "./default";

const data = (state = defaultdata, action) => {
  switch (action.type) {
    case "NEW_NODE":
      return newNode(state, action);

    case "EDIT_NODE":
      return editNode(state, action);

    case "EDIT_NODE_BLOCK":
      return editNodeBlock(state, action);

    case "DELETE_NODE":
      return deleteNode(state, action);

    default:
      return state;
  }
};

const editNode = (state, { id, data }) => {
  return {
    ...state,
    nodes: {
      ...state.nodes,
      [id]: {
        ...state.nodes[id],
        ...data,
      },
    },
  };
};

const newNode = (state, { name, parent_id, id }) => {
  if (!parent_id) return state;

  const node = {
    id,
    name,
    parent_id,
    blocks: [
      {
        id: uuidv4(),
        data: "",
        type: "text",
      },
    ],
  };

  return {
    ...state,
    nodes: {
      ...state.nodes,
      [id]: node,
    },
  };
};

const editNodeBlock = (state, { node_id, block_id, data }) => {
  const node = state.nodes[node_id];

  return {
    ...state,
    nodes: {
      ...state.nodes,
      [node_id]: {
        ...node,
        blocks: node.blocks.map(b => {
          if (b.id !== block_id) {
            return b;
          }

          return {
            ...b,
            data,
          };
        }),
      },
    },
  };
};

const deleteNode = (state, { id }) => {
  const { [id]: _, ...nodes } = state.nodes;
  return {
    ...state,
    nodes,
  };
};

export default data;

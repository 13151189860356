export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("SYSTEMS_STATE");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    // TODO: notify user of error
    console.error(err);
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("SYSTEMS_STATE", serializedState);
  } catch (err) {
    console.error(err);
  }
};

export const resetData = () => {
  localStorage.removeItem("SYSTEMS_STATE");
  window.location.reload();
};

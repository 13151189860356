import React from "react";
import Editor, { theme } from "@lsei/rich-markdown-editor";
import throttle from "lodash/throttle";
import PropType from "prop-types";

import "./Markdown.scss";

const hiddenToolbarButtons = {
    blocks: ["image", "table"],
    marks: [],
};

class Markdown extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.throttledChange = throttle(this.handleChange, 500, {
            leading: true,
            trailing: true,
        });
    }

    handleChange(getData) {
        const { id, nodeId, onChange } = this.props;
        onChange(nodeId, id, getData());
    }

    componentDidMount() {
        setTimeout(() => {
            // this.editor.editor.insertText("\nabc");
        }, 1000);
    }

    render() {
        const { id, data, readOnly, placeholder } = this.props;
        return (
            <div className="md-editor">
                <Editor
                    ref={(editor) => {
                        this.editor = editor;
                    }}
                    id={id}
                    defaultValue={data}
                    onChange={this.throttledChange}
                    theme={{ ...theme, hiddenToolbarButtons }}
                    placeholder={placeholder}
                    autoFocus={false}
                    tabIndex={11}
                    readOnly={readOnly}
                />
            </div>
        );
    }
}

Markdown.propTypes = {
    id: PropType.string.isRequired,
    nodeId: PropType.string.isRequired,
    data: PropType.string.isRequired,
    handleChange: PropType.func,
    placeholder: PropType.string,
};

Markdown.defaultProps = {
    handleChange: () => {},
    placeholder: "Write about your node here...",
};

export default Markdown;

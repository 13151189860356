import React from "react";
import { connect } from "react-redux";

import { setActiveWindow } from "../../actions/window";
import { editNode, deleteNode } from "../../actions/data";
import ContentBlock from "./ContentBlock";

import "./Editor.scss";
import { prepareDataForDropdown } from "../../utils/data";

const WINDOW_NAME = "editor";
const NEW_NODE_NAME = "New Node"; // TODO: move to consts file

class Editor extends React.Component {
    componentDidUpdate(prevProps, newState) {
        if (!this.nameInput) return;

        if (prevProps.selected !== this.props.selected && this.props.selected) {
            const currentNode = this.props.data.nodes[this.props.selected];

            // TODO: There has to be a better way to select it only if it was recently created...
            if (currentNode.name === NEW_NODE_NAME) {
                this.nameInput.select();
            }
        }
    }

    recursiveDeleteNode(id) {
        Object.values(this.props.data.nodes).forEach((node) => {
            if (node.parent_id === id) {
                this.recursiveDeleteNode(node.id);
            }
        });
        this.props.deleteNode(id);
    }

    render() {
        const { data, selected, makeActive, editNodeName, editNodeParentId } = this.props;

        const currentNode = data.nodes[selected];

        const dropdownData = prepareDataForDropdown(data, selected);

        return (
            <div className="editor" onClick={makeActive}>
                {currentNode ? (
                    <div>
                        <input
                            className="node-name"
                            type="text"
                            value={currentNode.name}
                            onChange={(e) => editNodeName(selected, e.target.value)}
                            ref={(r) => {
                                this.nameInput = r;
                            }}
                            tabIndex={10}
                        />
                        {currentNode.parent_id && (
                            <div className="parent">
                                Parent Node:{" "}
                                <select value={currentNode.parent_id} onChange={(e) => editNodeParentId(selected, e.target.value)}>
                                    {dropdownData.map((d) => {
                                        if (d.id === selected) return undefined;
                                        return (
                                            <option value={d.id} key={d.id}>
                                                {d.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        {currentNode.blocks && (
                            <div className="blocks">
                                {currentNode.blocks.map((b) => (
                                    <ContentBlock {...b} key={b.id} nodeId={currentNode.id} />
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <div>No node selected...</div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.data,
    selected: state.graph.selected,
});

const mapDispatchToProps = (dispatch) => ({
    makeActive: () => dispatch(setActiveWindow(WINDOW_NAME)),
    editNodeName: (id, name) => dispatch(editNode(id, { name })),
    editNodeParentId: (id, parent_id) => dispatch(editNode(id, { parent_id })),
    deleteNode: (id) => dispatch(deleteNode(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);

import React from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { closeOverlay } from "../../actions/window";

const OverlayWrapper = ({ children, closeOverlay }) => {
  const bgElem = useRef();

  return (
    <div
      ref={bgElem}
      onClick={e => {
        if (e.target === bgElem.current) {
          closeOverlay();
        }
      }}
      className="overlay-bg"
    >
      <div className="overlay-container">{children}</div>
    </div>
  );
};

const mapDispatchToProps = {
  closeOverlay,
};

export default connect(null, mapDispatchToProps)(OverlayWrapper);

import React, { useState } from "react";
import { connect } from "react-redux";

import OverlayWrapper from "./OverlayWrapper";
import {
  setCurrentDocument,
  setDocumentName,
  setWorkspaceName,
} from "../../actions/workspace";
import { closeOverlay } from "../../actions/window";
import { downloadState } from "../../utils/data";

const WorkspacesOverlay = ({
  state,
  setCurrentDocument,
  closeOverlay,
  setDocumentName,
  setWorkspaceName,
}) => {
  const { workspace } = state;

  const [workspaceRenameId, setWorkspaceRenameId] = useState("");
  const [documentRenameId, setDocumentRenameId] = useState("");

  return (
    <OverlayWrapper>
      <h2>
        Workspaces <button>+ New Workspace</button>
      </h2>
      <ul className="workspace-list">
        {Object.values(workspace.spaces).map(s => {
          const children = Object.values(workspace.documents).filter(
            d => d.space_id === s.id,
          );
          return (
            <li key={s.id} onClick={() => {}} className="workspace">
              <span className="item">
                <img
                  className="workspace-icon"
                  src="/assets/img/noun_nodes_2397553.svg"
                  alt="Workspace Icon"
                />{" "}
                {workspaceRenameId === s.id ? (
                  <>
                    <input
                      value={s.name}
                      onChange={e => {
                        setWorkspaceName(s.id, e.target.value);
                      }}
                    />
                    <button onClick={() => setWorkspaceRenameId("")}>OK</button>
                  </>
                ) : (
                  <>
                    {s.name}
                    <button onClick={() => setWorkspaceRenameId(s.id)}>
                      Rename Workspace
                    </button>
                    <button>+ Add Document To Workspace</button>
                  </>
                )}
              </span>
              {children && (
                <ul className="document-list">
                  {children.map(d => {
                    return (
                      <li key={d.id}>
                        {documentRenameId === d.id ? (
                          <>
                            <input
                              value={d.name}
                              onChange={e => {
                                setDocumentName(d.id, e.target.value);
                              }}
                            />
                            <button onClick={() => setDocumentRenameId("")}>
                              OK
                            </button>
                          </>
                        ) : (
                          <>
                            {d.name}
                            <button
                              onClick={() => {
                                setCurrentDocument(d.id);
                                closeOverlay();
                              }}
                            >
                              Switch to Document
                            </button>
                            <button onClick={() => setDocumentRenameId(d.id)}>
                              Rename Document
                            </button>
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
      <div className="data-export-options">
        <button
          onClick={() => {
            downloadState(state, "systems.json");
          }}
        >
          Download Workspace Backup
        </button>
      </div>
    </OverlayWrapper>
  );
};

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = {
  setCurrentDocument,
  closeOverlay,
  setDocumentName,
  setWorkspaceName,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacesOverlay);

import { combineReducers } from "redux";
import counter from "./counter";
import data from "./data/";
import graph from "./graph";
import window from "./window";
import workspace from "./workspace";

export default combineReducers({
  counter,
  data,
  graph,
  window,
  workspace,
});

import React from "react";
import { connect } from "react-redux";
import { newNode, deleteNode } from "../actions/data";
import { selectNodeById } from "../actions/graph";
import { closeOverlay } from "../actions/window";
import { NEW_NODE_NAME } from "../utils/contstants";

let keySequence = [];

class WindowManager extends React.Component {
    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleGraphKeys = this.handleGraphKeys.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
        document.addEventListener("keyup", this.handleKeyUp);
    }

    handleKeyDown(e) {
        keySequence.push(e.key);

        if (this.props.window.current_overlay !== null) {
            this.handleOverlayKeys(e);
            return;
        }

        if (this.props.window.active === "graph") {
            this.handleGraphKeys(e);
            return;
        }
    }

    handleKeyUp(e) {
        // TODO: should be specific to they key,
        // Find key and remove that?
        // Also, why is this not in state?
        keySequence = [];
    }

    handleGraphKeys(e) {
        const { graph, data, newNode, selectNodeById, deleteNode } = this.props;
        const key = keySequence.join("_");
        console.log(`[keypress] ${key}`);
        let node;

        const currentNode = data.nodes[graph.selected];

        switch (key) {
            case "Enter":
                node = newNode(graph.selected, NEW_NODE_NAME);
                selectNodeById(node.id);
                break;

            case "Control_Enter":
            case "Meta_Enter":
                if (!currentNode) break;
                node = newNode(currentNode.parent_id, NEW_NODE_NAME);
                selectNodeById(node.id);
                break;

            case "Control_Backspace":
            case "Meta_Backspace":
                if (!currentNode) break;
                if (!currentNode.parent_id) break;
                selectNodeById(currentNode.parent_id);
                deleteNode(graph.selected);
                break;

            default:
                break;
        }
    }

    handleOverlayKeys(e) {
        const { closeOverlay } = this.props;
        const key = keySequence.join("_");
        switch (key) {
            case "Escape":
                closeOverlay();
                break;
            default:
                break;
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = ({ window, graph, data }) => ({
    window,
    graph,
    data,
});

const mapDispatchToProps = {
    newNode,
    selectNodeById,
    closeOverlay,
    deleteNode,
};
export default connect(mapStateToProps, mapDispatchToProps)(WindowManager);

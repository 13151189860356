import { v4 as uuidv4 } from "uuid";

const defaultdata = {
    nodes: {
        "1": {
            id: "1",
            name: "Systems",
            blocks: [
                {
                    id: uuidv4(),
                    type: "text",
                    data: ``,
                },
            ],
        },

        "2": {
            id: "2",
            name: "My First Node",
            parent_id: "1",
            blocks: [
                {
                    id: uuidv4(),
                    type: "text",
                    data: `# This is your first node. 
        
Write something in here and make more nodes!`,
                },
            ],
        },
    },
};

export default defaultdata;
